import { IGetSurveyUserCommentsResponse } from '@/services/api-rest'

export interface IFeedback {
  name: string
  message: string
}

export const buildFeedbacks = (feedbackList: IGetSurveyUserCommentsResponse) =>
  feedbackList.map((feedback) => ({
    name: feedback.firstName,
    message: feedback.comment,
  }))

export const HOME_LESSONS_FEEDBACKS = [
  {
    name: 'Agathe',
    message: "Reprise du yoga après 1 an d'arrêt, c'était top !",
  },
  {
    name: 'Cécile',
    message:
      'Le cours de barre au sol avec Maxime est génial pour la rigueur de la discipline, le professionnalisme de Maxime et sa pédagogie fun et sportive',
  },
  {
    name: 'Pauline',
    message: 'Tout était parfait ! Vivement le prochain cours :-)',
  },
  {
    name: 'Marion',
    message:
      'Toujours un plaisir de réserver sur la plateforme et de connaitre de nouveaux professeurs... Jamais déçue !',
  },
  {
    name: 'Anita',
    message:
      'Très contente de la reprise des cours! Rien ne vaut les cours en « vrai « ! Surtout quand les profs sont de très bons niveaux .',
  },
  {
    name: 'Stephanie',
    message:
      'Très bien, que ce soit en salle ou en zoom, OLY be correspond à mes attentes.',
  },
  {
    name: 'Marianne',
    message: 'Supers profs, choix d’emplacements très bien',
  },
  {
    name: 'Faustine',
    message:
      'Excellent cours, très équilibré ! Je suis rentrée fatiguée de ma journée limite migraineuse et ressortie détendue, heureuse ,la tête légère .',
  },
  {
    name: 'Caroline',
    message:
      'Très appréciable d’avoir un si grand choix en terme d’enseignants et de lieux d’enseignements',
  },
]

export const STUDIO_LESSONS_FEEDBACKS = {
  all: [
    {
      name: 'Claire',
      message:
        "1ere expérience avec un cours de pilates, pas si facile que çà. Professeur à l'écoute de chacune de ses élèves et de leur niveau, qu'elle corrige individuellement si nécessaire. Curieusement je me tiens déjà plus droite. En route pour un second cours la semaine prochaine !",
    },
    {
      name: 'Anne Juliette',
      message:
        'Magali est l’une des meilleures profs de yoga de la plateforme ! Et sa playlist ajoute à l énergie qu elle met à enseigner. C était un bonheur de la retrouver en "vrai" ',
    },
    {
      name: 'Laetitia',
      message: 'J’ai adoré le cours! J’ai déjà réservé pour la semaine prochaine!',
    },
    {
      name: 'Véronique',
      message:
        'Cours correspondant exactement à mes besoins, prof super, et salle agréable. Rien à redire !',
    },
    {
      name: 'Mariam',
      message:
        'Trés bon cours! la prof était très à l écoute et pédagogue :) Je reviendrai sans hésitation :)',
    },
    {
      name: 'Magali',
      message:
        'Cours au top avec Amandine, un très bon moment pour bien démarrer la journée !',
    },
    {
      name: 'Ronan',
      message: 'Un super cours, du cardio et du renfo !',
    },
    {
      name: 'Sarah',
      message:
        'Je suis tres contente de l’app, des profs, de la variété de lieux et horaires.',
    },
    {
      name: 'Franck',
      message:
        'C’est cool de (re) pratiquer avec vous. Il faut reprendre le temps de découvrir les nouveaux profs pour organiser son planning. Léa est super :)',
    },
  ],
  paris: [
    {
      name: 'Cécilia',
      message:
        'Emma est vraiment une prof très pro., très bonnes explications, cours toujours différents. Un très très bon moment.',
    },
    {
      name: 'Anh',
      message: "C'était génial!! L'enseignante Marie est douce et bienveillante :)",
    },
    {
      name: 'Véronique',
      message:
        'Pratique bienveillante et explications très claires. Alexia corrige, adapte et veille à ce que chacun soit au mieux dans chaque posture. Ambiance au top avec musique zen et encens. A refaire chaque semaine ! :)',
    },
    {
      name: 'Lorely',
      message: 'Très satisfaite , Maxime est un prof très attentif',
    },
    {
      name: 'Frédéric',
      message:
        'Super cours, Vinyasa twisté, prof très attentionnée qui nous pousse un peu plus loin, juste ce qu il faut !',
    },
    {
      name: 'Keiko',
      message:
        "Claire est très sympa et envoyer bon vibe pour démarrer la journée en bon humeur, ça m'encourage de lever très tôt de matin !",
    },
    {
      name: 'Caroline',
      message:
        'Super cours qui propose encore une nouvelle facette de yoga parmi les cours oly be !',
    },
    {
      name: 'Chiraz',
      message:
        "C'était Génial 👍 et j'ajoute un Grand Merci à Marie R c'est une personne bienveillante, attentive, patiente, disponible et à l'écoute de ses élèves 😉",
    },
    {
      name: 'Laura',
      message:
        "Premier cours que je prenais avec cette prof et elle est extraordinaire ! Je pratique le yoga depuis 2 ans et c'était la première fois que je vivais une expérience physique et mentale aussi intense. Outre le contenu du cours qui était super, Océane est très souriante, motivante et bienveillante.",
    },
  ],
  bordeaux: [
    {
      name: 'Fanny',
      message:
        'Super professeure de Pilates ! C etait mon premier cours et j ai adoré ! Je la recommande vraiment !',
    },
    {
      name: 'Elisa',
      message:
        'Je suis super contente, je suis zen, relaxé et fière de moi et la prof est super je vais revenir c’est sur !',
    },
    {
      name: 'Camille',
      message:
        "Un cours structuré et fun à la fois. J'en ressors défoulée et détendue à la fois. Je recommande à 100%",
    },
    {
      name: 'Stéphanie',
      message: 'Un bonheur de pratiquer avec OlyBe !',
    },
    {
      name: 'Delphine',
      message:
        "Un très bon cours, original et efficace ! Dur dur les abdominaux 😀 L'endroit est agréable et propre ",
    },
    {
      name: 'Adilson',
      message:
        "L'application est très simple d’utilisation. Les cours sont de bonnes qualité avec des professeurs à l’écoute. Très belle expérience.",
    },
    {
      name: 'Iadine',
      message:
        'La prof est super avec des méditations superbes. Le cours repond bien à ses promesses.',
    },
    {
      name: 'Louise',
      message:
        'Première séance de Pilates avec Olybe. Très bonne expérience, je recommence la semaine prochaine ! Merci.   ',
    },
    {
      name: 'Souad',
      message:
        "Très simple d'accès. Professeure très agréable et attentive. Un cours qui allie plaisir et travail. Protocole sanitaire respecté. Lieu convivial. Je recommande sans hésiter.",
    },
  ],
  nantes: [
    {
      name: 'Géraldine',
      message:
        "prof exceptionnelle j'ai adoré !! douce bienvaillante une experience de yoga & souplesse à reproduire",
    },
    {
      name: 'Vincent',
      message:
        'Une prof très a l’écoute, attentionnée et très guidante. Un cours très bien construit, rythmé et fluide. Le lieu est propre et pratique. Je suis enthousiaste à l’idée de revenir pratiquer le yoga.',
    },
    {
      name: 'Johanna',
      message: 'Super cours et très joli lieu. Prof patiente et pédagogique',
    },
    {
      name: 'Julie',
      message:
        "Très facile de réservation, j'ai particulièrement apprécié l'offre découverte ainsi que la douceur et pédagogie de la prof, qui a su adapter son cours à mon niveau débutant; Je recommande à mes amies et je reviendrai dès que possible !",
    },
    {
      name: 'Stephanie',
      message:
        "Le lieu est proche de chez moi, le cours & le prof super, je ne manquerai pas de m'y réinscrire !",
    },
    {
      name: 'Celine',
      message:
        'Prof au top ! Cours parfait. Merci OLY BE, je n’ai jamais été déçue des prof de yoga avec lesquelles j’ai pratiqué.',
    },
    {
      name: 'Anita',
      message:
        'Génial en tous points: réservation, prix, et surtout les profs qui sont vraiment excellents !',
    },
    {
      name: 'Leila',
      message:
        'Premier cours avec OLY be et j en suis pleinement satisfaite. Gentillesse et douceur de la prof, contenu du cours, tout était parfait. Je n’ai pas vu le temps passer et reviendrai sans hésiter. Merci',
    },
    {
      name: 'Jessica',
      message:
        'Top, comme à chaque fois! La précision dans les explications sont un vrai plus dans vos cours, on est très bien guidé.e.s et la progression peut se mesurer',
    },
  ],
  annecy: [
    {
      name: 'Jennifer',
      message: 'Nous avons une prof super à l écoute et des cours au top !!!',
    },
    {
      name: 'Virginia',
      message: 'Cours dynamique, prof à l’écoute, attentif et pertinente. A refaire!',
    },
    {
      name: 'Sophie',
      message:
        'Bon cours. La professeure explique bien toutes les positions, les transitions et prends le temps de passer corriger nos postures. Merci 🙏',
    },
    {
      name: 'Pauline',
      message: 'Super cours, prof au top et lieux très chaleureux !!!',
    },
    {
      name: 'Sophie',
      message:
        'Très bon professeur avec des explications tout le long du cours. Un vrai moment de détente.',
    },
    {
      name: 'Dorothée',
      message: "Mon premier cours de yoga et j'en ressors très contente. Très bon prof",
    },
    {
      name: 'Marine',
      message:
        'Excellent cours, la prof était top et vérifie bien que les postures des participantes soient bien exécutées :)',
    },
    {
      name: 'Gaelle',
      message:
        'Réservation facile et simple. Cours super avec professeur génial ! Qui regarde les élèves et qui corrige. Cours parfait pour mon niveau : tonic au début puis doux sur la fin. Je recommande et j’y retourne !',
    },
    {
      name: 'Binta',
      message:
        'Bravo pour ce magnifique projet : les réservations sont fluides, le prof sympa, le lieu agréable. Au top !',
    },
  ],
  lyon: [
    {
      name: 'Stephane',
      message:
        'Le cours s’est très bien passé. Le niveau d’intensité était parfait et nous avons été très bien encadrés. Je retournerai à ce cours avec plaisir.',
    },
    {
      name: 'Julie',
      message:
        'Très bon moment avec un professeur qui nous montre les postures à prendre et n’hésite pas à nous reprendre. Gros travail aussi sur la respiration très appréciable. Je recommande.',
    },
    {
      name: 'Melissa',
      message: 'Super cours de yoga, bonne explication et emplacement top !',
    },
    {
      name: 'Aurianne',
      message: 'Très grande qualité des enseignements!',
    },
    {
      name: 'Virginia',
      message: 'Lieux cool et prof super sympa et a l’écoute',
    },
    {
      name: 'Sabrine',
      message:
        'Super prof. C est exactement ce que je cherchais. Je serai là toutes les semaines',
    },
    {
      name: 'Marc',
      message: 'Application géniale Professeur super lieu unique j’y retourne !',
    },
    {
      name: 'Sophie',
      message:
        'Super cours, Lieu agréable, Très bonne professeure. On prend le temps de travailler les postures et d’approfondir, Parfait pour un dimanche matin 🙏💚🍀',
    },
    {
      name: 'Carole',
      message:
        'Super cours! Prof bienveillante qui prends soin de vérifier que votre posture est bonne. De très bonnes explications. Très bonne ambiance. A refaire !',
    },
  ],
}

export const LIVE_LESSONS_FEEDBACKS = [
  {
    name: 'Sabine',
    message: "Je l'ai recommandé. Ma soeur est en période d'essai. 😉",
  },
  {
    name: 'Sara',
    message:
      "Très heureuse de pouvoir pratiquer le yoga régulièrement grâce à vous et j'apprécie particulièrement les cours de yoga de la femme d'Olivia qui me font toujours un bien fou !",
  },
  {
    name: 'Camille',
    message:
      "J'ai découvert oly be il y a quelques mois et je recommande à mon entourage !! J'adore les cours online,les profs sont super vraiment, merci",
  },
  {
    name: 'Noemie',
    message:
      '2e session toujours aussi agréable. Un réveil tout en douceur et tonicité. Parfait! Je cours voir le planning du reste de la semaine !',
  },
  {
    name: 'Elise',
    message:
      "Superbe cours. La prof réussi à insuffler du dynamisme et créer un vrai lien bien qu'elle ne puisse pas nous voir. C'est top! Exactement ce qu'il me fallait ce soir!",
  },
  {
    name: 'Agnès',
    message:
      'Super atelier avec Valentine qui est pleine de bienveillance, comme ça fait du bien. Merci beaucoup !',
  },
  {
    name: 'Laura',
    message:
      'Les cours d’Isabelle sont toujours un vrai moment de détente, elle sait accompagner de sa voix très agréable pour un moment de sérénité.',
  },
  {
    name: 'Ludivine',
    message:
      'Merci beaucoup pour le programme que vous proposez. Les cours sont super et je n’ai jamais été déçue par un professeur, avec le replay, je peux encore plus profiter de mon abonnement. Merci !',
  },
  {
    name: 'Sophie',
    message:
      "Super ! Les cours avec Layla changent à chaque fois, elle explique bien, elle a la pêche. C'est vraiment top !",
  },
]

export const REPLAY_LESSON_FEEDBACKS = [
  {
    name: 'Frédérique',
    message:
      'Excellent site, beaucoup de cours proposés avec en plus un système de replay très pratique.',
  },
  {
    name: 'Sylvie',
    message:
      "Encore une nouvelle expérience pilates réussie. C'est du luxe d'avoir plusieurs profs de Pilates :)",
  },
  {
    name: 'Noemie',
    message:
      '1er cours avec vous et je suis emballée! Tout était parfait, vidéo, son, playlist à dispo sur spotify, prof très maîtrisant et accessible. Bref, je me suis dej a réinscrire à une prochaine session !',
  },
  {
    name: 'Sophie',
    message:
      'Top ! Layla est une super prof. Le format hyper intense de 20 minutes est génial !',
  },
  {
    name: 'Victoria',
    message: 'Super cours et des profs très sympathiques et bienveillants',
  },
  {
    name: 'Agnès',
    message: "J'adore votre plate-forme, c'est top, merci pour tout !",
  },
  {
    name: 'Alexia',
    message:
      'Très bon professeur Joyce, beaucoup d’explication et on voit très bien les mouvements ! Top',
  },
  {
    name: 'Elodie',
    message:
      'Une pause agréable dans la journée ! Des exercices qui changent pour travailler tout le corps, ça fait du bien !',
  },
  {
    name: 'Constance',
    message: 'Mary est vraiment super, en cours comme en online !',
  },
]

export const B2B_FEEDBACKS = [
  {
    name: 'Catherine, CEO - Integer France/TBWA',
    message:
      'En parallèle de la découverte de musée, exposition, film, … Nous avons choisi de mettre en place des cours de sport avec OLY Be. L’expérience OLY Be est efficace et nous sommes toujours écoutés ! Il y a un engouement fort pour cette activité. C’est considéré comme un moment de pause et de partage entre les collaborateurs.',
  },
  {
    name: 'Eglantine, Head of brand content and Partner - Matter Paris',
    message:
      "Faire appel à une société comme OLY Be c’est avoir à disposition une équipe professionnelle et à l'écoute qui sait adapter sa prestation aux attentes de l'entreprise et aussi des salariés. Ainsi en fonction des besoins ou envies de chacun nous travaillons des aspects spécifiques comme la gestion du stress, le travail de la posture ou encore la relaxation.",
  },
  {
    name: 'Marie, Chargée de projets Développement RH - Eurosport',
    message:
      'OLY Be propose des intervenants qualifiés et pédagogues, hautement appréciés par nos collaborateurs. Il nous a donc semblé naturel et logique de continuer à travailler avec eux et ce, malgré les restrictions dues à la crise sanitaire. Plus que jamais, nos collaborateurs ont besoin d’être accompagnés mentalement et physiquement pour favoriser leur bien-être, même à distance.',
  },
]
