import React from 'react'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

import { Container } from './Container'

interface IProps {
  title?: string
  sectionTitle?: string
  isSectionTitleH1?: boolean
  className?: string
}

export const GenericSlice: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  title,
  sectionTitle,
  isSectionTitleH1 = false,
  className,
}) => (
  <Wrapper className={className}>
    <StyledContainer>
      {sectionTitle && (
        <>
          <SectionTitle>
            {isSectionTitleH1 ? <h1>{sectionTitle}</h1> : sectionTitle}
          </SectionTitle>
          <HorizontalRule />
        </>
      )}

      {title && <Title>{title}</Title>}
    </StyledContainer>
    <Content>{children}</Content>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 80px 0;
`
const StyledContainer = styled(Container)`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }
`
export const SectionTitle = styled.div`
  ${({ theme }) => theme.typo.overline}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    color: ${({ theme }) => theme.color.greyDarker};
  }
`
export const HorizontalRule = styled.hr`
  background-color: ${({ theme }) => theme.color.black};
  border: none;
  height: 1px;
  margin: ${({ theme }) => `${theme.spacing.s} auto ${theme.spacing.ms}`};
  width: 160px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: 240px;
  }
`
const Title = styled.h2`
  ${({ theme }) => theme.typo.h2}

  margin-bottom: ${({ theme }) => theme.spacing.m};
  text-align: center;
`
const Content = styled.section``
