import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { QuotationIcon } from '@/components/_svg/icons/QuotationIcon'
import { DEVICE_WIDTH } from '@/utils/constants'
import { IFeedback } from '@/utils/feedbacks'

export interface IFeedbacksCarouselProps {
  feedbacks: IFeedback[]
}

export const FeedbacksCarousel: React.FC<IFeedbacksCarouselProps> = ({ feedbacks }) => {
  const carouselRef = useRef<HTMLDivElement>()
  const lastItemRef = useRef<HTMLDivElement>()
  const [scrollOffset, setScrollOffset] = useState(0)
  const [isLastItemVisible, setIsLastItemVisible] = useState(false)

  useEffect(() => {
    carouselRef.current.scrollTo({ top: 0, left: scrollOffset, behavior: 'smooth' })
  }, [scrollOffset])

  const showPreviousFeedbacks = () => {
    setScrollOffset((prevValue) => prevValue - carouselRef.current.clientWidth)
    setIsLastItemVisible(false)
  }

  const showNextFeedbacks = () => {
    setScrollOffset((prevValue) => prevValue + carouselRef.current?.clientWidth)
    setIsLastItemVisible(() => {
      const lastItem = lastItemRef.current
      if (!lastItem) {
        return false
      }
      const right =
        lastItem.getBoundingClientRect().right - carouselRef.current?.clientWidth
      return right <= window.innerWidth
    })
  }

  return (
    <Wrapper>
      <LeftQuotationIcon />
      <FeedbacksList ref={carouselRef} $nbItems={feedbacks.length}>
        {feedbacks.map((feedback, index) => (
          <FeedbackItem
            key={index}
            ref={index === feedbacks.length - 1 ? lastItemRef : null}
          >
            <FeedbackMessage>“ {feedback.message} ”</FeedbackMessage>
            <FeedbackName>{feedback.name}</FeedbackName>
          </FeedbackItem>
        ))}
      </FeedbacksList>
      <RightQuotationIcon />

      <ButtonsWrapper $nbItems={feedbacks.length}>
        <CustomButton round onClick={showPreviousFeedbacks} disabled={scrollOffset <= 0}>
          ←
        </CustomButton>
        <CustomButton round onClick={showNextFeedbacks} disabled={isLastItemVisible}>
          →
        </CustomButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  }
`
const FeedbackMessage = styled.p`
  ${({ theme }) => theme.typo.bold}

  text-align: center;
`
const FeedbackName = styled.p`
  ${({ theme }) => theme.typo.mention}

  color: ${({ theme }) => theme.color.grey};
  margin-top: ${({ theme }) => theme.spacing.s};
  text-align: center;
`
const ButtonsWrapper = styled.div<{ $nbItems: number }>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.ms};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }

  ${({ $nbItems }) => {
    if ($nbItems <= 1) {
      return css`
        display: none;
      `
    }
    if ($nbItems <= 3) {
      return css`
        @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
          display: none;
        }
      `
    }
  }}
`
const LeftQuotationIcon = styled(QuotationIcon)`
  fill: ${({ theme }) => theme.color.mandarine};
  margin-top: -${({ theme }) => theme.spacing.xxl};
  position: absolute;
  transform: rotate(180deg);

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    height: 82px;
    margin-top: -135px;
    width: 106px;
  }
`
const RightQuotationIcon = styled(QuotationIcon)`
  fill: ${({ theme }) => theme.color.pink};
  margin-top: -${({ theme }) => theme.spacing.m};
  position: absolute;
  right: ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    height: 49px;
    margin-top: ${({ theme }) => theme.spacing.l};
    width: 63px;
  }
`
const FeedbacksList = styled.div<{ $nbItems: number }>`
  -ms-overflow-style: none;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  ${({ $nbItems }) =>
    $nbItems <= 2 &&
    css`
      @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
        justify-content: center;
      }
    `}

  &::-webkit-scrollbar {
    display: none;
  }
`
const FeedbackItem = styled.div`
  flex: 0 0 auto;
  scroll-snap-align: center;
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: ${({ theme }) => theme.spacing.l};
    width: 33.33%;

    &:not(:nth-child(3n), :last-child) {
      border-right: 1px solid ${({ theme }) => theme.color.greyLighter};
    }
  }
`
