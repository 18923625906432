import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const QuotationIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill={theme.color.terracota}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M30.2703 -2.67029e-05C32.1121 -2.67029e-05 33.6336 0.357571 34.8348 1.07277C36.036 1.78797 37.037 2.74157 37.8378 3.93356C38.7187 5.20502 39.3193 6.63542 39.6396 8.22475C39.8799 9.81408 40 11.2445 40 12.5159C40 16.4098 38.999 19.9858 36.997 23.2439C34.995 26.502 31.8719 29.1244 27.6276 31.1111L26.5465 28.9655C28.9489 27.9324 31.031 26.3431 32.7928 24.1975C34.5546 22.0519 35.5155 19.8666 35.6757 17.6415C35.7558 16.4495 35.6757 15.337 35.4354 14.3039C33.994 15.6549 32.2322 16.3303 30.1502 16.3303C27.8278 16.3303 25.8659 15.6151 24.2643 14.1847C22.6627 12.7543 21.8619 10.7677 21.8619 8.22475C21.8619 5.76129 22.7027 3.77463 24.3844 2.26476C25.986 0.754902 27.9479 -2.67029e-05 30.2703 -2.67029e-05ZM8.40841 -2.67029e-05C10.2502 -2.67029e-05 11.7718 0.357571 12.973 1.07277C14.1742 1.78797 15.1752 2.74157 15.976 3.93356C16.8569 5.20502 17.4575 6.63542 17.7778 8.22475C18.018 9.81408 18.1381 11.2445 18.1381 12.5159C18.1381 16.4098 17.1371 19.9858 15.1351 23.2439C13.1331 26.502 10.01 29.1244 5.76577 31.1111L4.68469 28.9655C7.08709 27.9324 9.16917 26.3431 10.9309 24.1975C12.6927 22.0519 13.6537 19.8666 13.8138 17.6415C13.8939 16.4495 13.8138 15.337 13.5736 14.3039C12.1321 15.6549 10.3704 16.3303 8.28829 16.3303C5.96597 16.3303 4.00401 15.6151 2.4024 14.1847C0.800804 12.7543 0 10.7677 0 8.22475C0 5.76129 0.840843 3.77463 2.52253 2.26476C4.12413 0.754902 6.08609 -2.67029e-05 8.40841 -2.67029e-05Z" />
  </svg>
)
